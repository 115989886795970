<template>
    <div class="min-h-full relative max-h-full flex flex-col text-base-content">
        <sub-header :backButton="true" class="mb-4" backRedirectionUrl="/packages" :customCrumbLists="customBreadCumbs" />
        <div class="justify-between flex-grow max-h-full overflow-y-auto">
            <div class="flex h-full gap-4 flex-1 overflow-y-auto">
                <div class="w-2/12 rounded-lg self-start bg-white shadow flex flex-col gap-x-4 overflow-y-auto scroll-bar">
                    <div
                        @click="handleCurrentTab(nav)"
                        v-for="(nav, idx) in navigations"
                        :key="idx"
                        class="px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3  cursor-pointer"
                        :class="{
                            ' bg-primary-300 hover:bg-primary-200 text-base-content-800': currentTab === nav.nav,
                            'hover:bg-gray-100   hover:text-base-content-600': currentTab !== nav.nav,
                            'border-t border-gray-400 border-0' : idx != 0
                        }"
                    >
                        <!-- :style="{borderColor: currentTab === nav.nav ? '#cdcdcd': '' }" -->
                        <font-awesome-icon :icon="[nav.iconPrefix || 'fa', nav.icon]" />
                        {{ nav.title }}
                        <!-- <font-awesome-icon class="text-gray-500 absolute right-2" v-if="currentTab === nav.nav" icon="arrow-right" /> -->
                    </div>
                </div>

                <div v-if="currentTab" class="flex-1 relative bg-white rounded-md shadow g h-full overflow-y-auto flex flex-col">
                    <div v-if="packageData" class="px-4 lg:px-5 py-3 flex gap-3 items-center bg-primary-300 border-b border-gray-200 text-base-content-800 text-lg font-medium">
                        <span class="font-bold">Package: </span>
                        {{ packageData.package_name }}
                    </div>
                    <div class="p-3 overflow-y-scroll scroll-bar h-full flex-1 flex max-h-full pb-4">
                        <component v-bind:is="currentComponent" @getSlaData="getSlaData" :emptySlaData="emptySlaData" @isErrorPresent="isErrorPresent"> </component>
                    </div>
                </div>
                <div v-else class="flex flex-1 bg-white p-3 h-full overflow-y-scroll scroll-bar justify-center items-center">No Options Selected</div>
            </div>
        </div>
    </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
// End
const Description = () => import("@/components/tenant-packages/description/description");
const Checks = () => import("@/components/tenant-packages/checks");
// const CandidateForm = () => import("@/components/tenant-packages/candidate-form-view");
// const RefereeForm = () => import("@/components/tenant-packages/referee-form-view");
// const ScreenerForm = () => import("@/components/tenant-packages/screener-form-view");
// const ClientForm = () => import("@/components/tenant-packages/client-form-view");
// const WelcomeScreen = () => import("@/components/tenant-packages/welcome-screen");
const FormEditor = () => import("@/components/tenant-packages/form-editor");

const Tat = () => import("@/components/tenant-packages/tat/tat");

const FormPreview = () => import("@/components/tenant-packages/form-preview");
const EmailSequence = () => import("@/components/tenant-packages/email-sequence")
const LinkSetting = () => import("@/components/tenant-packages/link-setting/index")
const AssignedTenant = () => import("@/components/tenant-packages/assigned-tenant/index")
import { mapActions, mapGetters } from "vuex";

// const Email = () => import("@/components/tenant-packages/email/email");
export default {
    title: 'Packages',
    name: "screeing-package-view",
    components: {
        SubHeader,
        "neo-button": Button,
        description: Description,
        checks: Checks,
        EmailSequence,
        LinkSetting,
        // "email": Email,
    },
    props: {},
    data: () => ({
        navigations: [
            {
                title: "Checks",
                nav: "checks",
                icon: "circle-check",
                subnav: false,
                iconPrefix: "far",
            },
            {
                title: "Description",
                nav: "description",
                icon: "file-lines",
                subnav: false,
                iconPrefix: "far",
            },
            { title: "SLA", nav: "sla", icon: "clock", subnav: false },
            { title: "Default Email Sequence", nav: "email", icon: "envelope", subnav: false },
            { title: "Link Setting", nav: "link-setting", icon: "link", subnav: false },
            // {
            //     title: "Welcome Screen",
            //     nav: "welcome-screen",
            //     icon: "desktop",
            //     subnav: false,
            // },
            {
                title: "Form Editor",
                nav: "form-editor",
                icon: "table",
                subnav: false,
            },
			{
                title: "Form Preview",
                nav: "form-preview",
                icon: "tv",
                subnav: false,
            },
            {
                title: "Assigned Tenant",
                nav: "assigned-tenant",
                icon: "user-check",
                subnav: false,
            },
            // { title: "Client Form", nav: "client-form", icon: "user", subnav: false },
            
            // {
            //     title: "Subject Form",
            //     nav: "candidate-form",
            //     icon: "user-friends",
            //     subnav: false,
            // },
            // {
            //     title: "Referee Form",
            //     nav: "referee-form",
            //     icon: "user-friends",
            //     subnav: false,
            // },
            // {
            //     title: "Analyst Form",
            //     nav: "screener-form",
            //     icon: "tv",
            //     subnav: false,
            // },

            // { title: 'Email', nav: 'email', icon: 'envelope', 'subnav': false,},
        ],
        currentTab: null,
        screeningMenuWrapper: {
            description: Description,
            checks: Checks,
            "email": EmailSequence,
            // "candidate-form": CandidateForm,
            // "referee-form": RefereeForm,
            sla: Tat,
            // "screener-form": Analyst,
            // "client-form": ClientForm,
            // "welcome-screen": WelcomeScreen,
            "form-editor": FormEditor,
            "form-preview": FormPreview,
            'link-setting': LinkSetting,
            'assigned-tenant': AssignedTenant
        },
        componentData: null,
        packageData: null,
        customBreadCumbs: [],
        slaData: "",
        emptySlaData: "",
        isSlaNotValid: false,
    }),
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        let roles = this.getRoles;
        if (!roles.length) {
            await this.fetchRoles();
        }
        await this.fetchPackageData(this.$route.params.packageName);
        this.packageData = this.getPackage;
    },
    created() {
        let params = this.$route.params;
        if (params.packagetool) {
            this.currentTab = params.packagetool;
            this.$route.meta.customCrumbs = [{ name: params.packagetool }];
            this.$forceUpdate();
        } else {
            this.currentTab = this.navigations[0].nav;
            this.$router.push({
                name: "Package Tools",
                params: { packagetool: this.navigations[0].nav },
            });
            this.setBredCrumbs(this.currentTab)
            this.$forceUpdate();
        }
    },
    computed: {
        ...mapGetters(["getPackage", "getRoles"]),
        subDomainAvailable() {
            let selectedTab = this.currentTab;
            if (selectedTab) {
                let currentNav = this.navigations.find((ele) => ele.nav === selectedTab);
                return currentNav.subnav;
            } else {
                return "";
            }
        },
        currentComponent() {
            return this.screeningMenuWrapper[this.currentTab];
        },
    },

    methods: {
        ...mapActions(["fetchPackageData", "fetchRoles"]),
        /***
         *  triggers when tabs changed
         */
        handleCurrentTab(tab) {
            if (this.currentTab === tab.nav) return;
            this.currentTab = tab.nav;
            this.setBredCrumbs(this.currentTab);
            this.$router.push({
                name: "Package Tools",
                params: { packagetool: this.currentTab },
            });
            if(this.slaData < 0 || this.isSlaNotValid){
                this.emptySlaData = ""
            }
            else {
                this.emptySlaData = this.slaData
            }
        },
        setBredCrumbs(crumb) {
            this.customBreadCumbs = [{ name: crumb }];
        },
        getSlaData(data){
            this.slaData = data;
        },
        isErrorPresent(data){
            this.isSlaNotValid = data;
        }
    },
};
</script>
<style scoped lang="scss">
.screeing_nav {
    border: 1px solid transparent;
}
.screeing_nav:hover {
    border: 1px solid #cdcdcd;
}
::v-deep {
    .ql-container {
        .ql-editor {
            min-height: 20rem;
            height: 20rem;
        }
    }
}
</style>
